@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* fonts used */
/* font-family: 'Montserrat',
sans-serif;
font-family: 'Poppins',
sans-serif; */

* 
{
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

:root 
{
  --backgrounColor: #000000;
  --textColor: #FFFFFF;
  --btnBg: #FFFFFF;
  --grayBackgroundColor: rgba(217, 217, 217, 0.1);
  --grayBorderColor:rgba(255, 255, 255, 0.3);
  --lightTextColor: #ffffff95;
  --redColor:rgb(255, 129, 129);
  --blackTextColor:#000000;
  --scrobarWidth:12px;
  --subButtonBg: #ffffff;
  --statTextColor: rgb(194, 191, 191);
  --navBarBg:rgba(0, 0, 0, 0.564);
  --btnShadow: 0 4px 8px rgba(255, 255, 255, 0.5);
  --newCompBack:#ffffff;
  --pgmGray:rgb(129, 129, 129);
  --pgmTextHover:rgb(129, 129, 129);
  --pgmBgHover: rgb(144, 144, 144);
  --activePgm:#000000;
  --depFont: white;
  --depBullet: white;
  --textColor2: #000000;
  --stuNav:white;
  --headColor:black;
  --nameColor:black;
  --formText:black;
  --instrFont:white;
  --shadow:white;
  
}

[data-theme="light"]
{
  --backgrounColor: rgb(224, 236, 255);
  --newCompBack:rgb(224, 236, 255);
  --textColor: rgba(1,89,170,255);
  --btnBg: #000000;
  --grayBackgroundColor:rgb(188 188 188 / 10%);
  --grayBorderColor:rgba(0, 0, 0, 0.3);
  --lightTextColor: #00000095;
  --redColor:rgb(129, 175, 255);
  --blackTextColor:#FFFFFF;
  --subButtonBg: rgba(1,89,170,255);
  --statTextColor: rgb(225, 224, 224);
  --navBarBg:rgba(255, 255, 255, 0.564);
  --btnShadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  --activePgm:#FFF;
  --depFont: #246;
  --depBullet: #48b;
  --textColor2: rgba(1,89,170,255);
  --pgmGray: rgba(1,89,170,255);
  --pgmTextHover: white;
  --stuNav:rgba(1,89,170,255);
  --headColor:rgb(255, 255, 255);
  --nameColor:rgba(1, 89, 170, 255);
  --pgmBgHover: rgba(1,89,170,255);
  --formText: rgba(1,89,170,255);
  --instrFont:rgba(1, 89, 170, 255);
  --shadow:rgba(0, 0, 0, 0.2);
}

*{
  transition: all 0.3s ease-in-out;
}
body 
{
  background: var(--backgrounColor);
  overflow-x: hidden;
  width: 100vw;
}

/* scrollbar */
body::-webkit-scrollbar
{
  width: var(--scrobarWidth);
  border-radius: var(--scrobarWidth);
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(31, 31, 31, 0.3);
  background-color: var(--grayBackgroundColor) ;
}
body::-webkit-scrollbar-thumb{
  background-color: white;
  border-radius: var(--scrobarWidth);
}
/* Font family and weights */
/* examples */
/* mainHeading --> Why Recruit from NIT Calicut? */
/* mediumHeading --> Industry-Ready Skills */
/* smallHeading -->  Paragraphs */
/* tinyTexts --->  company names */


.mainHeading{
  font-family: 'Poppins',sans-serif;
  font-weight: 700;
  font-size: 3rem;
  line-height: 4.5rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  margin-top: 1.5rem;
}

.mediumHeading
{
  font-family: 'Montserrat',sans-serif;
  font-size: 1.5rem;
}

.smallHeading
{
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
}

.tinyTexts
{
    font-family: 'Poppins', sans-serif;
    font-size: 0.75rem;
}

/* Button styles  */

.button
{
  background-color: var(--btnBg);
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
}

/* anchor tag styles */
a 
{
  color: text;
  text-decoration: none;
}

/* list type */
li{
  list-style-type: none;
}


.whiteButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--textColor);
  padding: 1rem;
  border-radius: 2rem;
  width: fit-content;
  cursor: pointer;
  transition: background-color 0.3s ease,border 0.3s ease;; 
}

.whiteButton:hover {
  background-color: var(--backgrounColor);
  box-shadow:var(--btnShadow);
}

.buttonText {
  color: var(--blackTextColor);
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 560;
  padding: 0rem 0.6rem;
  transition: color 0.3s ease; 
}

.whiteButton:hover .buttonText {
  color: var(--textColor);
}

/* .arrow {
  width: 1rem;
  transition: stroke 0.3s ease; 
  stroke: var(--blackTextColor);
}

.whiteButton:hover .arrow path {
  stroke: var(--textColor);
} */



@media screen and (min-width:769px) and (max-width:1025px) {

  .mainHeading
  {
    font-size: 2.75rem;
    line-height: normal;
    padding: 1rem;
  }
  .mediumHeading
  {
    font-size: 1.35rem;
  }
  .smallHeading
  {
    font-size: 0.985rem;
  }
}

@media screen and (min-width:425px) and (max-width:769px)
{
  .mainHeading
  {
    font-size: 2.4rem;
    line-height: normal;
    padding: 1rem;
  }
  .mediumHeading
  {
    font-size: 1.25rem;
  }
  .smallHeading
  {
    font-size: 0.85rem;
  }
}
@media screen and (max-width:426px) {
  .mainHeading
  {
    font-size: 1.5rem;
    line-height: normal;
    padding: 1rem;
  }
  .smallHeading
  {
    font-size: 0.75rem;
  }
}
