:root {
  --formBg: black;
  --formTxt: white;
}

[data-theme="light"] {
  --formBg: white;
  --formTxt: black;
}

* {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

header {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #dee2e6;
}

header h1 {
  margin: 0;
  font-size: 3vw;
  color: #ff0000;
}

header p {
  margin: 10px 0 0;
  font-size: 1.2em;
  color: #6c757d;
}

section {
  padding: 20px;
  border-bottom: 1px solid #dee2e6;
}

section h2 {
  font-size: 2em;
  color: #3c556d;
}

section p {
  font-size: 1.1em;
  color: #495057;
}

/* Form Styling */
.form-container {
  max-width: 90vw;
  margin: 5vh auto;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  margin-top: 12vh;
}

.form-header {
  text-align: center;
  margin-bottom: 20px;
}

.form-header h1 {
  font-size: 2em;
  color: #343a40;
}

.form-header p {
  font-size: 1.2em;
  color: #6c757d;
}

.summer-form {
  display: flex;
  flex-direction: column;
}

.form-section {
  margin-bottom: 20px;
}

.form-section h2 {
  font-size: 1.5em;
  color: var(--textColor2);
  margin-bottom: 10px;
}
.form-section h3{
  color: rgb(174, 0, 0);
  padding-bottom: 0.3rem;
}
.form-group p{
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
}
.form-group {
  margin-bottom: 15px;
}
.form-group{
  color: var(--textColor2);
}
.form-group label {
  display: block;
  font-size: 1em;
  color: var(--formTxt);
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.form-group input.error,
.form-group select.error,
.form-group textarea.error {
  border-color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 0.875em;
  margin-top: 5px;
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.checkbox-group input {
  margin-right: 10px;
}

/* Progress Bar */
.progress-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  overflow-x: auto;
  scrollbar-width: none;
}

.progress-step {
  flex: 1;
  padding: 10px;
  text-align: center;
  background-color: #e9ecef;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  margin-right: 5px;
  color: var(--textColor2);
  min-width: 20vw;
}

.progress-step:last-child {
  margin-right: 0;
}

.progress-step.active {
  background-color: var(--textColor2);
  color: #ffffff;
  font-weight: bold;
}

/* Buttons */
.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1vw;
}

.prev-button,
.next-button {
  padding: 10px;
  font-size: 1em;
  color: #ffffff;
  background-color: var(--textColor2);
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.submit-button1{
  margin-top: 1vw;
  padding: 10px;
  font-size: 1em;
  color: #ffffff;
  background-color: var(--textColor2);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.submit-button1:hover {
  background-color: #0677f0;
}

/* Mentor Filter */
.mentor-filter-container {
  max-width: inherit;
  margin: auto;
  padding: 24px;
  background: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: 1px solid #ddd;
  color: var(--textColor2)
}
.mentor-filter-container h3{
  padding-bottom: 0.3vh;
  color: rgb(174, 0, 0);

}
.title {
  font-size: 24px;
  font-weight: bold;;
  margin-bottom: 16px;
}

.form-group label {
  font-weight: 600;
  color: var(--textColor2);
  display: block;
  margin-bottom: 8px;
}

.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
}

/* Checkbox Grid */
.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  background: #f3f3f3;
  padding: 10px;
  border-radius: 6px;
  /* width: fit-content; */
}
.checkbox-group {
  display: flex;
  align-items: center; /* Ensures proper vertical alignment */
  gap: 8px; /* Controls spacing between checkbox and text */
}

.checkbox-group input {
  width: 18px; /* Standard checkbox size */
  height: 18px;
  margin-right: 8px; /* Adjusts spacing between checkbox and label */
}

.checkbox-label input {
  width: 18px;
  height: 18px;
}

.checkbox-label span {
  padding-left: 1vw;
}

/* Apply Button */
.button-wrap {
  display: flex;
  justify-content: space-between;
}

.apply-button {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  font-weight: bold;
  background: var(--textColor2);
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s;
}

.apply-button:hover {
  background: #0056b3;
}

.apply-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

/* Table */
.mentor-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
  background: white;
}

.mentor-table th,
.mentor-table td {
  padding: 12px;
  border: 1px solid #ccc;
  text-align: left;
}

.mentor-table th {
  background: #007bff;
  color: white;
  font-weight: bold;
}

.mentor-table tr:nth-child(even) {
  background: #f8f9fa;
}

.mentor-table tr:nth-child(odd) {
  background: white;
}

.mentor-table tr:hover {
  background: #e2e6ea;
  transition: 0.3s;
}
.checkbox-group{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
/* Responsive Design */
@media (max-width: 768px) {
  header h1 {
    font-size: 5vw;
  }

  .form-container {
    width: 95vw;
    margin: 5vh auto;
    padding: 2vh;
    margin-top: 10vh;
  }

  .progress-bar {
    display: flex;
    overflow-x: auto;
  }

  .progress-step {
    min-width: 25vw;
    font-size: 3vw;
    text-align: center;
    padding: 1vh;
    line-height: 1.2;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    font-size: 0.85rem;
    padding: 2vh;
  }

  .submit-button,
  .prev-button,
  .next-button {
    font-size: 3vw;
    padding: 2vh;
    width: 100%;
    margin-bottom: 0.5vh;
  }
  .submit-button{
    display: flex;
    justify-content: center;
  }
  .mentor-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .checkbox-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
